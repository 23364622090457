import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import AccountMyCouponsScreen from '../../screens/account/my-coupons'

const AccountCouponsPage = (props) => (
  <Router>
    <AccountMyCouponsScreen
      {...props}
      path={`${_.get(props, 'pageContext.locale')}/user/coupons/tokens/:couponTokenToken`}
    />
  </Router>
)

export default AccountCouponsPage
